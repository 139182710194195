<template>
    <div class="flex h-full">
        <div class="hidden lg:flex lg:justify-center lg:items-center text-8xl text-black w-[60%] select-none">
            <img src="@/assets/logo.svg" alt="Transo" width="300" /> Transo.
        </div>
        <div class="flex flex-col justify-center items-center flex-1">
            <img src="@/assets/logo.svg" alt="Transo" width="80" class="mb-14 lg:hidden absolute top-2 left-0" />
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts"></script>
